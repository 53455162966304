<template>
  <div class="board_wapper">
    <div class="board" :style="[setBoardStyle(index)]" @click="userBoard" v-for="(board, index) in data" :key="index">
      <div class="board_info">
        <div class="title">{{ board.title }}</div>
        <div class="count">233</div>
      </div>
      <div class="board_cover">
        <div class="large">
          <el-image :src="board.preview[0].url" fit="cover"></el-image>
        </div>
        <div class="small">
          <div class="subimg"><el-image :src="board.preview[1].url" fit="cover"></el-image></div>
          <div class="subimg"><el-image :src="board.preview[2].url" fit="cover"></el-image></div>
          <div class="subimg"><el-image :src="board.preview[3].url" fit="cover"></el-image></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {
      deviceWidth: '',
      gutter: 10,
    }
  },
  computed: {
    col() {
      if (this.deviceWidth >= 1024) {
        return 4;
      } else if (this.deviceWidth >= 768) {
        return 3;
      } else if (this.deviceWidth >= 375) {
        return 2;
      } else {
        return 1;
      }
    }
  },
  methods: {
    setBoardStyle(index) {
      return {
        'width': (this.deviceWidth - (this.col + 1)*this.gutter) / this.col + 'px',
        'margin-left': this.gutter + 'px',
      }
    },
    userBoard() {
      this.$router.push({
        path: '/mCollectBoard',
        query: {id: Math.random(1, 100)}
      });
    },
  },
  mounted() {
    this.deviceWidth = document.documentElement.offsetWidth || document.body.offsetWidth;
  },
}
</script>

<style lang="scss" scoped>
.board_wapper {
  box-sizing: border-box;
  width: 100%;
  background: #f5f5f5;
  padding-top: .75em;
  .board {
    box-sizing: border-box;
    padding: .3em;
    margin-bottom: .75em;
    display: inline-block;
    height: 100%;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.02), 0 1px 3px 0 rgba(0,0,0,.02);
    .board_info {
      display: flex;
      justify-content: space-between;
      font-size: .9em;
      margin-bottom: .75em;
      .title {
        color: #444;
      }
      .count {
        color: #999;
      }
    }
    .board_cover {
      .large {
        box-shadow: inset 0 0 2px rgba(0,0,0,.2);
        margin-bottom: .35em;
      }
      .small {
        display: flex;
        justify-content: space-between;
        height: 3.5em;
        overflow: hidden;
        .subimg {
          height: 100%;
          flex: 1;
          margin-right: .35em;
          box-shadow: inset 0 0 2px rgba(0,0,0,.2);
          .el-image {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>