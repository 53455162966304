<template>
  <div class="user_collect">
    <div class="user_info">
      <div class="avatar">
        <el-avatar :size="50" src="https://acg.xydwz.cn/gqapi/gqapi.php"></el-avatar>
      </div>
      <div class="name">用户名</div>
    </div>
    <div class="tabs_wapper">
      <div class="tabs">
        <div class="tab" :class="[{'active': tabIndex == 0}]" @click="changeTab(0)">{{ collectCount }} 采集</div>
        <div class="tab" :class="[{'active': tabIndex == 1}]" @click="changeTab(1)">{{ boardCount }} 画板</div>
      </div>
    </div>
    <div class="main_part">
      <div v-if="tabIndex === 0">
        <mp-waterfall :data="collectData" @loadmore="moreCollect"></mp-waterfall>
        <no-more :show="noMore"></no-more>
      </div>
      <div v-if="tabIndex === 1">
        <mp-board :data="boardData"></mp-board>
      </div>
    </div>
  </div>
</template>

<script>
import MpWaterfall from '../../components/mp-waterfall';
import MpBoard from '../../components/mp-board';
import NoMore from '../../components/mp-no-more';
export default {
  components: {
    MpWaterfall,
    MpBoard,
    NoMore,
  },
  data() {
    return {
      tabIndex: 0,
      collectData: [],
      collectCount: 0,
      query: {catId: '', username: '', ord: '', title: '', use: '', page: 1},
      boardData: [],
      boardCount: 0,
      boardQuery: {title: '', use: 0, username: '', cat_id: ''},
      noMore: false,
    }
  },
  methods: {
    changeTab(num) {  // 采集、画板切换
      this.tabIndex = num;
    },
    getCollectData() {  // 采集数据
      this.$service.DEFAULT({
        url: '/auth/ppt/fodder/list',
        method: 'GET',
        params: {
          cat_id: this.query.catId,
          username: this.query.username,
          ord: this.query.ord,
          title: this.query.title,
          use: this.query.use,
          page: this.query.page,
        },
      }).then(res => {
        if (res.code == 200) {
          this.collectData = this.collectData.concat(res.data.res);
          this.collectCount = res.data.count;
          if (res.data.res.length < 20) {
            this.noMore = true;
          }
        }
      })
    },
    moreCollect() {  // 加载更多采集
      this.query.page += 1;
      this.getCollectData();
    },
    getBoardData() {  // 画板数据
      this.$service.DEFAULT({
        url: '/auth/ppt/board/list',
        method: 'GET',
        params: {
          title: this.boardQuery.catId,
          use: this.boardQuery.use,
          username: this.boardQuery.username,
          cat_id: this.boardQuery.cat_id,
        },
      }).then(res => {
        if (res.code == 200) {
          this.boardData = res.data.res;
          this.boardCount = res.data.count;
        }
      })
    },
    scrolling() {
      let scrollTop = document.documentElement.scrollTop;
      let screenHeight = window.screen.availHeight;
      let contentHeight = document.documentElement.offsetHeight;
      if (scrollTop + screenHeight == contentHeight) {
        this.data = this.data.concat(this.data)
      }
    },
  },
  created() {
    this.getCollectData();
    this.getBoardData();
  },
  mounted() {
    document.addEventListener('scroll', this.scrolling, true);
  },
  destroyed() {
    document.removeEventListener('scroll', this.scrolling, true);
  },
}
</script>

<style lang="scss" scoped>
.user_collect {
  box-sizing: border-box;
  width: 100%;
  background: #f5f5f5;
  .user_info {
    padding: 0 1em;
    background: #fff;
    display: flex;
    align-items: center;
    .avatar {
      margin-right: 1em;
    }
    .name {
      color: #444;
      font-weight: 600;
    }
  }
  .tabs_wapper {
    background: #fff;
    padding: 1em;
    .tabs {
      border: 1px solid #ededed;
      display: flex;
      text-align: center;
      .tab {
        flex: 1;
        padding: .8em 0;
        &.active {
          background: #f5f5f5;
        }
      }
    }    
  }
}
</style>